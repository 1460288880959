import type { ReactNode } from "react";
import { cva, cx, type RecipeVariantProps } from "~/design/generated/css";

const container = cva({
  base: {
    margin: "auto",
    width: "full",
  },
  variants: {
    variant: {
      narrow: {
        px: { base: 2, md: 6, lg: 8 },
        maxWidth: "800px",
      },
      normal: {
        px: { base: 2, md: 6, lg: 8 },
        maxWidth: "1350px",
      },
      full: {
        px: { base: 2, md: 6, lg: 8 },
        maxWidth: "1500px",
      },
      none: {
        maxWidth: "1800px",
      },
    },
  },
  defaultVariants: {
    variant: "normal",
  },
});

type ContainerProps = RecipeVariantProps<typeof container> & {
  children: ReactNode;
  className?: string;
};

export function Container({ children, className, variant }: ContainerProps) {
  const containerClass = container({ variant });
  return <div className={cx(containerClass, className)}>{children}</div>;
}
